@import "../../scss/config/all";

.info-panel_container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: ($z-masthead + 10);
  width: 100%;
  max-width: 40rem;
  max-height: 100vh;
  transition: transform $ease-swoosh $speed-std;
  @extend %box-shadow;

  &[data-open="false"] {
    transform: translateX(100%);
  }

  &[data-open="true"] {
    transform: none;
  }

  .info-panel_content_container {
    @extend %grid;
    @extend %grid_column;
    @extend %align-start;
    @extend %justify-start;
    @extend %theme-mineshaft;
    width: 100%;
    height: 100%;

    .tabs_container {
      @extend %grid;
      @extend %grid_column;
      @extend %align-start;
      @extend %justify-start;
      width: 100%;
      height: 100%;
      flex-grow: 1;
    }

    .info-panel_content,
    .tabs_tab {
      @extend %grid;
      @extend %grid_column;
      @extend %align-start;
      @extend %justify-start;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      flex-grow: 1;
      @extend %theme-mako;
      @extend %padding-standard;


      .button_group {
        margin-top: auto;
        width: 100%;
        @extend %grid;
        @extend %grid_row;
        @extend %align-center;
      }
    }

    fieldset {
      padding: 0;
    }
  }
}
