%button {
  @extend button;
  margin-left: 1em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:first-of-type { margin-left: 0; }

  span {
    position: relative;
  }

  @extend %button-border;
  display: inline-block;
  text-align: center;
  line-height: 1em;

  &:not(.button-icon-only) {
    @extend %border_radius-std;
    padding: 0.5em 1em;

    svg,
    span {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0.25em;
    }

    svg {
      font-size: inherit;
      width: 1em;
      height: 1em;
    }

    span {
      @extend %junior;
      font-size: inherit;
      line-height: 1em;
    }
  }

  &.button-large {
    @extend %button-large;
  }

  &.button-standard {
    @extend %button-standard;
  }

  &.button-small {
    @extend %button-small;
  }
}

button::-moz-focus-outer {
  border: 0; // Removes the dotted line on Firefox
}

a[class*="button"] {
  text-decoration: none; // Removes the default underline to make the text match actual buttons
}

%button-border {
  border-width: $border_width-std;
  border-style: solid;
}

%button-large {
  &:not(.button-icon-only) {
    min-width: 12em;
    font-size: 1.5rem;
  }
}

%button-standard {
  &:not(.button-icon-only) {
    min-width: 9em;
    font-size: 1rem;
  }
}

%button-small {
  &:not(.button-icon-only) {
    min-width: auto;
    font-size: 0.75rem;
  }
}
